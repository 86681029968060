<svelte:options tag="my-opener" />
<script>
    import {
        onMount,
        createEventDispatcher
    } from 'svelte';

    export let openOddsRecord;
onMount(() => {
       
    }) 

function properTime(date){
        let hour = new Date(date).getUTCHours();
        let min = new Date(date).getUTCMinutes();
        hour = hour == 0 ? hour + 1 : hour;
        console.log(min);
        // hour = hour.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2});
        // const gameHour = hour > 12 ? Math.abs(hour - 12).toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2}) + ' PM' : hour + ' AM';
	   const gameHour = hour > 12 ? Math.abs(hour - 12) + `.${min != 0 ? String(min).padStart(2, "0")
 : '00'} PM` : hour + `.${min != 0 ? String(min).padStart(2, "0") : '00'} AM`;
        return gameHour;
	}

function properDate(date){
		return new Date(date).toLocaleDateString("en-US");
	}

function displayDate(oddsRecord,oddRecord,i) {
        if(oddsRecord.length != i){
            let index = i;
            index = index == 0 ? 1 : index;
            debugger;
            const oddsRecordTime = properDate(oddsRecord[index-1].schedule.date);
            const oddRecordTime = properDate(oddsRecord[index].schedule.date);
            // return oddsRecordTime != oddRecordTime ? oddRecordTime : '';

            if(i == 0){
                return oddRecordTime;
            }
            else {
                return oddsRecordTime != oddRecordTime ? oddRecordTime : '';
            }
        }
    }
</script>

<div class="table-responsive">
    <table class="table table-hover table-bordered">
        <thead>
            <tr>
                <th>Time</th>
                <th>Team</th>
                <th>Spread</th>
                <th>Moneyline</th>
                <th>Total</th>
            </tr>
        </thead>
        {#each openOddsRecord as odd, i}
        <tbody>
        <td colspan="5" class="daterow">
                {displayDate(openOddsRecord,odd,i)}
        </td>
        <tr>
            <td>{properTime(odd.schedule.date)}</td>
            <td>
                <span>{odd.teams.away.team}</span><br/>
                <span>{odd.teams.home.team}</span>
            </td>
            {#if odd.status == 'scheduled'}
                {#each odd.odds as record}
                    <td>
                        {#if record.spread != undefined}
                        <span>{record.spread.open.away > 0 ? '+' : ''}{record.spread.open.away}  ({record.spread.open.awayOdds > 0 ? '+' : ''}{record.spread.open.awayOdds})</span> <br/>
                        <span>{record.spread.open.home > 0 ? '+' : ''}{record.spread.open.home} ({record.spread.open.homeOdds > 0 ? '+' : ''}{record.spread.open.homeOdds})</span>
                        {/if}
                    </td>
                    <td>
                        {#if record.moneyline != undefined}
                        <span>{record.moneyline.open.awayOdds > 0 ? '+' : ''}{record.moneyline.open.awayOdds} </span> <br/>
                        <span>{record.moneyline.open.homeOdds > 0 ? '+' : ''}{record.moneyline.open.homeOdds}</span>
                        {/if}
                    </td>
                    <td>
                        {#if record.total != undefined}
                        <span>{record.total.open.total > 0 ? '+' : ''}{record.total.open.total}  ({record.total.open.overOdds > 0 ? '+' : ''}{record.total.open.overOdds})</span> <br/>
                        <span>{record.total.open.total > 0 ? '+' : ''}{record.total.open.total} ({record.total.open.underOdds > 0 ? '+' : ''}{record.total.open.underOdds})</span>
                        {/if}
                    </td>
                {/each}
            {/if}
        </tr>
       </tbody>
        {/each}
</table>
    </div>

<style>

td.daterow {
    font-size: 14px;
    font-weight: bolder;
}

td.daterow:empty {
 display:none
}

.table {
    font-family: Verdana,Arial,sans-serif;
    font-size: 12px;
}

.table thead th {
    font-size: 16px;
    font-family: 'Circular-Loom';
    text-transform: uppercase;
    text-align: center;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    text-align: center;
}
</style>