<svelte:options tag="my-nav" />
<script>
    import {
        onMount,
        createEventDispatcher
    } from 'svelte';
    const dispatch = createEventDispatcher();

	export let selectedItem = '';
    export let odds = [];
    export let selectedOdds = '';
    
    onMount(() => {
        // getOdds();        
    });

    function selectedItemhandler(item) {
        selectedItem = item;
        dispatch('mainMenuHandler', item);
    }

    function leagueItemhandler(item,label) {
        dispatch('subMenuHandler', {item,label});
    }
</script>
<nav class="navbar navbar-expand-sm bg-secondary navbar-dark">
    <ul class="navbar-nav">
        {#if odds.length > 0}
        {#each odds as odd}
            <li class="nav-item {selectedOdds == odd.label ? 'active' : ''}">
                <p class="nav-link" on:click={()=> leagueItemhandler(odd.name,odd.label)}>{odd.label}</p>
            </li>
        {/each}
        {/if}        
    </ul>
</nav>

<style>

    .bg-secondary {
         margin: 0px 0px 30px 0px;
    }
</style>