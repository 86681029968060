<svelte:options tag="my-tableodds" />
<script>
    import {
        onMount,
        createEventDispatcher
    } from 'svelte';
    const dispatch = createEventDispatcher();

    const oddsHeading = ['Time/Tv', 'Rot', 'Wagers', 'Opener'];

    export let oddsRecord;
    // export let selectedOddType='';
    export let selectedOddSubType;
    // export let selectedSportName;

    function properDate(date){
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString("en-US", options)
	}

    function displayDate(oddsRecord,oddRecord,i) {
        if(oddsRecord.length != i){
            console.log('******');
            console.log(i);

            let index = i;
            index = index == 0 ? 1 : index;
            const oddsRecordTime = properDate(oddsRecord[index-1].commence_time);
            const oddRecordTime = properDate(oddsRecord[index].commence_time);
            if(i == 0){
                return oddRecordTime;
            }
            else {
                return oddsRecordTime != oddRecordTime ? oddRecordTime : '';
            }
        }
    }

    function properTime(date){
        let hour = new Date(date).getUTCHours();
        let min = new Date(date).getUTCMinutes();
        hour = hour == 0 ? hour + 1 : hour;
        hour = hour + 3;
	   const gameHour = hour > 12 ? Math.abs(hour - 12) + `.${min != 0 ? String(min).padStart(2, "0")
 : '00'} PM` : hour + `.${min != 0 ? String(min).padStart(2, "0") : '00'} AM`;
        return gameHour;
	}

    function addDecial(num) {
                return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2});
    }
</script>

<div class="table-responsive">
    <table class="table table-hover table-bordered">
        <thead>
            <tr>
                <th>Time</th>
                <th>Team</th>
                <th>Bovada</th>
                <th>MyBookie</th>
                <th>Bonline</th>
            </tr>
        </thead>
        <!-- <td>{JSON.stringify(oddsRecord)} -->
        {#if selectedOddSubType == 'spreads'}
            {#each oddsRecord as oddRecord, i}
                <tbody>
                <tr>
                    <td colspan="5" class="daterow">
                        {displayDate(oddsRecord,oddRecord,i)}
                    </td>
                </tr>
                <tr>
                    <td>{properTime(oddRecord.commence_time)}</td>
                    <td>
                        <span>{oddRecord.teams[1]}</span><br/>
                        <span>{oddRecord.teams[0]}</span>
                    </td>
                    <td>
                        {#each oddRecord.sites.filter(a =>  a.site_nice == 'Bovada') as site , i}
                                {#if site.site_nice == 'Bovada'}
                                <span><label for="site-points13">{site.odds.spreads.points[0] > 0 ? '+' : ''}{site.odds.spreads.points[0]}</label>  <label>({site.odds.spreads.odds[0] > 0 ? '+' : ''}{site.odds.spreads.odds[0]})</label></span> <br/>
                                <span><label for="site-points14">{site.odds.spreads.points[1] > 0 ? '+' : ''}{site.odds.spreads.points[1]}</label>  <label>({site.odds.spreads.odds[1] > 0 ? '+' : ''}{site.odds.spreads.odds[1]})</label></span>
                                {:else}
                                    <span>-</span>
                                {/if}
                        {/each}   
                    </td>

                    <td>
                        {#each oddRecord.sites.filter(a =>  a.site_nice == 'BetOnline.ag') as site , i}
                                {#if site.site_nice == 'BetOnline.ag'}
                                <span><label for="site-points11">{site.odds.spreads.points[0] > 0 ? '+' : ''}{site.odds.spreads.points[0]}</label>  <label>({site.odds.spreads.odds[0] > 0 ? '+' : ''}{site.odds.spreads.odds[0]})</label></span> <br/>
                                <span><label for="site-points12">{site.odds.spreads.points[1] > 0 ?'+' : ''}{site.odds.spreads.points[1]}</label>  <label>({site.odds.spreads.odds[1] > 0 ?'+' : ''}{site.odds.spreads.odds[1]})</label></span>
                                {:else}
                                    <span>-</span>
                                {/if}
                        {/each}   
                    </td>

                    <td>
                        {#each oddRecord.sites.filter(a =>  a.site_nice == 'MyBookie.ag') as site , i}
                                {#if site.site_nice == 'MyBookie.ag'}
                                <span><label for="site-points9">{site.odds.spreads.points[0] > 0 ? '+' : ''}{site.odds.spreads.points[0]}</label>  <label>({site.odds.spreads.odds[0] > 0 ? '+' :''}{site.odds.spreads.odds[0]})</label></span> <br/>
                                <span><label for="site-points10">{site.odds.spreads.points[1] > 0 ? '+' : ''}{site.odds.spreads.points[1]}</label>  <label>({site.odds.spreads.odds[1] > 0 ? '+' : ''}{site.odds.spreads.odds[1]})</label></span>
                                {:else}
                                    <span>-</span>
                                {/if}
                        {/each}   
                    </td>
                </tr>
            </tbody>
            {/each}
        {/if}

        {#if selectedOddSubType == 'h2h'}
        {#each oddsRecord as oddRecord, i}
            <tbody>
                <tr>
                    <td colspan="5" class="daterow">
                        {displayDate(oddsRecord,oddRecord,i)}
                    </td>
                </tr>
            <tr>
                <td>{properTime(oddRecord.commence_time)}</td>
                <td>
                    <span>{oddRecord.teams[1]}</span><br/>
                    <span>{oddRecord.teams[0]}</span>
                </td>
                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'Bovada') as site , i}
                            {#if site.site_nice == 'Bovada'}
                            <span>{site.odds.h2h[1] > 0 ? '+' : ''}{site.odds.h2h[1]}</span><br/>
                            <span>{site.odds.h2h[0] > 0 ? '+' : ''}{site.odds.h2h[0]}</span> 
                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>

                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'BetOnline.ag') as site , i}
                            {#if site.site_nice == 'BetOnline.ag'}
                            <span>{site.odds.h2h[1] > 0 ? '+' : ''}{site.odds.h2h[1]}</span><br/>
                            <span>{site.odds.h2h[0] > 0 ? '+' : ''}{site.odds.h2h[0]}</span> 
                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>

                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'MyBookie.ag') as site , i}
                            {#if site.site_nice == 'MyBookie.ag'}
                            <span>{site.odds.h2h[1] > 0 ? '+' : ''}{site.odds.h2h[1]}</span><br/>
                            <span>{site.odds.h2h[0] > 0 ? '+' : ''}{site.odds.h2h[0]}</span> 

                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>
            </tr>
        </tbody>
        {/each}
        {/if}

        {#if selectedOddSubType == 'totals'}
        {#each oddsRecord as oddRecord, i}
            <tbody>
                <tr>
                    <td colspan="5" class="daterow">
                        {displayDate(oddsRecord,oddRecord,i)}
                    </td>
                </tr>
            <tr>
                <td>{properTime(oddRecord.commence_time)}</td>
                <td>
                    <span>{oddRecord.teams[1]}</span><br/>
                    <span>{oddRecord.teams[0]}</span>
                </td>
                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'Bovada') as site , i}
                            {#if site.site_nice == 'Bovada'}
                            <span>
                                 <label  for="site-points5">{site.odds.totals.points[1] > 0 ? '+' : ''}{site.odds.totals.points[1]}</label>
                                 <label for="site-points6">({site.odds.totals.odds[1] > 0 ? '+' : ''}{site.odds.totals.odds[1]})</label>
                            </span><br/>
                            <span>
                                <label for="site-points7">{site.odds.totals.points[0] > 0 ? '+' : ''}{site.odds.totals.points[0]}</label> 
                                <label for="site-points8">({site.odds.totals.odds[0] > 0 ? '+' : ''}{site.odds.totals.odds[0]})</label>
                            </span> 
                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>

                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'BetOnline.ag') as site , i}
                            {#if site.site_nice == 'BetOnline.ag'}
                            <span>
                                <label  for="site-points1">{site.odds.totals.points[1] > 0 ? '+' : ''}{site.odds.totals.points[1]}</label>
                                <label  for="site-points2">({site.odds.totals.odds[1] > 0 ? '+' : ''}{site.odds.totals.odds[1]})</label>
                            </span><br/>
                            <span>
                                <label  for="site-points3">{site.odds.totals.points[0] > 0 ? '+' : ''}{site.odds.totals.points[0]}</label>
                                <label  for="site-points4">({site.odds.totals.odds[0] > 0 ? '+' : ''}{site.odds.totals.odds[0]})</label>
                            </span> 
                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>

                <td>
                    {#each oddRecord.sites.filter(a =>  a.site_nice == 'MyBookie.ag') as site , i}
                            {#if site.site_nice == 'MyBookie.ag'}
                            <span>
                                <label for="site-total1">{site.odds.totals.points[1] > 0 ? '+' : ''}{site.odds.totals.points[1]}</label>
                                <label for="odds-total1">({site.odds.totals.odds[1] > 0 ? '+' : ''}{site.odds.totals.odds[1]})</label>
                            </span><br/>
                            <span>
                                <label  for="site-total2">{site.odds.totals.points[0] > 0 ? '+' : ''}{site.odds.totals.points[0]}</label>
                                <label  for="site-total2">({site.odds.totals.odds[0] > 0 ? '+' : ''}{site.odds.totals.odds[0]})</label></span> 

                            {:else}
                                <span>-</span>
                            {/if}
                    {/each}   
                </td>
            </tr>
        </tbody>
        {/each}
        {/if}
    </table>
</div>

<style>

td.daterow {
    font-size: 14px;
    font-weight: bolder;
}

span label {
    width: 20px;
    padding: 0px 20px;
    text-align: center;
}
table {
    font-family: Verdana,Arial,sans-serif;
    font-size: 12px;
}

.table td {
    padding: 5px 10px;
}

table p span {
    font-size: 14px;
    color: #697785;
    padding:0px 10px;
}


td.daterow:empty {
 display:none
}

.table thead th {
    font-size: 16px;
    font-family: 'Circular-Loom';
    text-transform: uppercase;
    text-align: center;
}

.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    text-align: center;
}

</style>