<svelte:options tag="my-odds" />
<script>
	import {onMount,createEventDispatcher} from 'svelte';
  import NavMenu from './oddsMenu/navbar.svelte';
  import OddsTable from './table/oddsTable.svelte';
  import OpenerTable from './table/openerTable.svelte';

  let oddsRecord = [];
  let oddOpenTypeshandlerDisplay = false;
  let openOddsRecord = [];
  const baseUrl = 'https://api.the-odds-api.com/v3/odds/?sport=upcoming&region=us&mkt=spreads&dateFormat=iso&apiKey=';
  const API_KEY='b7be8e5bc0d58b07b147ec1dfe20352c';

  let menuItems = ['Live odds', 'Futures', 'Matchup'];
  let selectedItem = menuItems[0];
  // let odds = ['NFL', 'MLB', 'NBA', 'NCAA', 'NHL'];
  let initialLoad = 0;
  // let oddTypes = ['Full Game','1st Half','2nd Half','1Q','2Q','3Q'];

  let oddTypes = [{name:'Game',label:'Full Game'},{name:'FirstHalf',label:'1st Half'},{name:'SecondHalf',label:'2nd Half'},{name:'FirstQuarter',label:'1Q'},{name:'SecondQuarter',label:'2Q'},{name:'ThirdQuarter',label:'3Q'},{name:'FourthQuarter',label:'4Q'}];


  let oddSubTypes = ['Point Spreads' , 'Money Lines' , 'Total'  ];
  let oddSubTypesData = [{name:'spreads',label:'Point Spreads'} , {name:'h2h',label:'Money Lines'} , {name:'totals',label:'Total'}];

  let selectedOddType = oddTypes[0].name;
  // let selectedOddSubType = initialLoad == 0 ? '' : oddSubTypesData[0].name;
  let selectedOddSubType =  oddSubTypesData[0].name;

  let selectedoddSubTypeData = initialLoad == 0 ? '' : oddSubTypesData[0].name;
  let sportsData = [
   {name:'baseball_mlb',label:'MLB'},
   {name:'basketball_nba',label:'NBA'},
   {name:'basketball_ncaab',label:'NCAAB'},
   {name:'icehockey_nhl',label:'NHL'},
  ];
  let selectedOdds = sportsData[0].label;

  let selectedSportName = sportsData[0].name;
  onMount(() => {
    performGetRequest();
    performOpenerGetRequest();
  });

  function performGetRequest (url) {
      const http = new XMLHttpRequest()
			http.open('GET', `https://api.the-odds-api.com/v3/odds/?sport=${selectedSportName}&region=us&mkt=${selectedOddSubType}&dateFormat=iso&oddsFormat=american&apiKey=b7be8e5bc0d58b07b147ec1dfe20352c`)
			http.setRequestHeader('Content-type', 'application/json')
			http.send() 
			http.onload = function() {
        let data = JSON.parse(http.responseText).data;
        data = data.filter(item => item.sites.filter(a => a.site_nice == 'MyBookie.ag' || a.site_nice == 'BetOnline.ag' || a.site_nice == 'Bovada'));
        oddsRecord = data;
			}
    }

    function performOpenerGetRequest (url) {
      const http = new XMLHttpRequest()
			http.open('GET', `https://sportspage-feeds.p.rapidapi.com/games?league=${selectedOdds}`)
      http.setRequestHeader("x-rapidapi-key", "f8ccfb9df4mshda798da8d50f145p18dd39jsn9cf8c892836c");
      http.setRequestHeader("x-rapidapi-host", "sportspage-feeds.p.rapidapi.com");
			http.setRequestHeader('Content-type', 'application/json')
			http.send() 
			http.onload = function() {
        let data = JSON.parse(http.responseText).results;
        data = data.filter(data => data.odds != undefined);
        openOddsRecord = data;
        console.log(openOddsRecord);
			}
    }

  function getOdds (item) {
    // console.log(`${baseUrl}`+`${item}`);
   // return performGetRequest(`${baseUrl}`+`${item}`);
}

function mainMenuHandler(payload) {
  console.log(payload);
}

function subMenuHandler(payload) {
  selectedOdds = payload.detail.label;
  selectedSportName = payload.detail.item;
  performGetRequest();
  performOpenerGetRequest();
  console.log(payload);
  //selectedSportName
}

function oddTypeshandler(item) { 
  initialLoad += 1;
  selectedOddSubType =  oddSubTypes[0];
  selectedOddType = item;
}

function oddSubTypeshandler(item) {
  oddOpenTypeshandlerDisplay = false;
  initialLoad += 1;
  selectedOddSubType =  oddSubTypes[0];
  selectedOddSubType = item;
  oddsRecord = [];
  performGetRequest();
}

function oddOpenTypeshandler(item) {
  oddOpenTypeshandlerDisplay = !oddOpenTypeshandlerDisplay;
}


</script>
<div class="articleContainer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <NavMenu 
        menuItems={menuItems}
        selectedItem={selectedItem}
        odds={sportsData}
        on:mainMenuHandler = {mainMenuHandler} 
        on:subMenuHandler = {subMenuHandler} 
        selectedOdds={selectedOdds}
        />
        <h4 class="heading">{selectedOdds} Odds, Lines and Spreads</h4>
        <ul class="oddTypes oddSubTypes">
          {#each oddSubTypesData as item}
              <li 
              class={selectedOddSubType == item.name && !oddOpenTypeshandlerDisplay ? 'active' : ''}
              on:click={()=> oddSubTypeshandler(item.name)}><span>{item.label}</span></li>
          {/each}
          <li 
          class={oddOpenTypeshandlerDisplay ? 'active' : ''}
          on:click={()=> oddOpenTypeshandler(selectedOdds)}>Opener</li>
        </ul>

        <div>
          {#if !oddOpenTypeshandlerDisplay && oddsRecord.length > 0}
            <OddsTable
             selectedSportName={selectedSportName}
             oddsRecord={oddsRecord} 
             selectedOddType={selectedOddType} 
             selectedOddSubType={selectedOddSubType} />
             {:else if !oddOpenTypeshandlerDisplay && oddsRecord.length == 0}
             <p>No Odds available for {selectedSportName} league</p>
          {/if}
          <!-- {JSON.stringify(oddsRecord)} -->
        </div>

        <div>
            {#if oddOpenTypeshandlerDisplay && openOddsRecord.length > 0}
            <OpenerTable openOddsRecord={openOddsRecord}/>
            {:else if oddOpenTypeshandlerDisplay && openOddsRecord.length == 0}
              <p>No Opener available for league</p>
            {/if}
        </div>
        
      </div>
    </div>
  </div>
</div>
<style>
    ul.oddTypes {
      display: flex;
      padding-left:0px;
    }
  
    ul.oddSubTypes {
      padding-left:0px;
  
    }
  
    ul.oddTypes li {
      list-style: none;
      display: inline;
      color: #090909;
      font-size: 1rem;
      font-weight: bold;
      border-bottom: .2rem solid #e3e5e6;
      padding-bottom: 5px;
    }
  
    ul.oddTypes.oddSubTypes li{
      border:none !important;
      font-size: 0.91rem;
    }
  
    ul.oddTypes.oddSubTypes li:hover{
      border-bottom: none !important;
      color: #1883d5;
    }
  
    ul.oddTypes li span {
      padding:5px 20px;
    }
  
    ul.oddTypes li:hover {
      border-bottom: .2rem solid #1883d5 !important;
    }
  
    ul.oddTypes li.active {
      border-bottom: .2rem solid #1883d5 !important;
    }
  
    ul.oddTypes.oddSubTypes li.active{
      border-bottom: none !important;
      color: #1883d5;
    }
  
    .heading {
      margin-bottom: 25px;
      border-bottom: 2px solid #e83a25;
  }
  </style>